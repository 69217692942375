<template>
  <div class="train-user">
    <div class="item_title">
      <div class="item_titleline"></div>
      培训管理
    </div>
    <div class="content">
      <a-form-model
        ref="form"
        :model="form"
        class="search_form page-form-search"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 17 }"
        labelAlign="left"
      >
        <a-row class="row_item">
          <a-col :span="6">
            <a-form-model-item label="培训名称">
              <a-input v-model="form.title" placeholder="请输入培训名称" allowClear />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="培训类别" prop="categoryId">
              <a-cascader
                :options="cateOption"
                placeholder="请选择培训类别"
                v-model="form.categoryId"
                :show-search="{ filter: filterOption }"
                allowClear
                :fieldNames="{
                  label: 'name',
                  value: 'id',
                  children: 'list'
                }"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="创建时间">
              <a-range-picker v-model="form.createTime" value-format="YYYY-MM-DD" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <div class="form_btn_box" v-perms="'search'">
              <a-button type @click="handleReset">重置</a-button>
              <a-button type="primary" style="margin-left: 12px" @click="handleSearch">搜索</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form-model>
      <div class="table-content">
        <p-table
          ref="table"
          row-key="id"
          :columns="columns"
          :sourceData="getDataApi"
          :scorll="{ y: 0 }"
          extraHeight="440"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        >
          <template slot="headerLeft">
            <div>
              <a-button type="primary" @click="handleAdd" :disabled="!isPerms" v-perms="'add'">新增</a-button>
              <a-button
                type="primary"
                @click="handleDeleteAll"
                :disabled="!selectedRowKeys.length"
                v-perms="'delete-all'"
              >批量删除</a-button
              >
            </div>
          </template>
          <template slot="action" slot-scope="text, record">
            <a-button type="link" @click="$refs.trainPlanForm.lookModel(record.id)" v-perms="'details'">详情</a-button>
            <a-popconfirm
              title="确认删除吗？"
              @confirm="handleDelete(record)"
              ok-text="是"
              cancel-text="否"
              v-perms="'delete'"
            >
              <a-button type="link">删除</a-button>
            </a-popconfirm>
            <a-button type="link" @click="handleOpenPlan(record)" v-perms="'pxqk'">培训情况</a-button>
          </template>
        </p-table>
      </div>
    </div>
    <trainPlanForm ref="trainPlanForm" :cateOption="cateOption" @success="$refs.table.refresh()" />
    <trainUser ref="trainUser" />
  </div>
</template>
<script>
import { deleteAllTrainPlan, getTrainPlanData, getAllcateType, getTrainPerms, getTreeByType } from '@/api/train'
import trainPlanForm from './form.vue'
import moment from 'moment'
import trainUser from './train-user'

export default {
  components: {
    trainPlanForm,
    trainUser
  },
  data () {
    return {
      form: {},
      selectedRowKeys: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: 60,
          customRender: (t, r, i) => i + 1
        },
        {
          title: '培训计划名称',
          dataIndex: 'title',
          key: 'title'
        },
        {
          title: '培训类别',
          dataIndex: 'category',
          key: 'category'
        },
        {
          title: '创建人',
          dataIndex: 'createBy',
          key: 'createBy'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          customRender: (t) => moment(t).format('YYYY-MM-DD HH:mm:ss')
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 260,
          key: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      cateOption: [],
      curPerms: null
    }
  },
  computed: {
    isPerms () {
      if (this.curPerms) {
        return this.curPerms.status != 0
      }
      return false
    }
  },
  created () {
    this.handleGetPerms()
    getTreeByType({ type: 4 }).then(res => {
      this.cateOption = res.map(item => {
        const list = item.list.map(listItem => {
          return {
            ...listItem,
            disabled: !listItem.list || !listItem.list.length
          }
        })
        return {
          ...item,
          list,
          disabled: list.every(listItem => listItem.disabled)
        }
      })
    })
  },
  methods: {
    handleGetPerms () {
      getTrainPerms().then((res) => {
        if (res.data.length) {
          this.curPerms = res.data[0]
          this.$nextTick(() => {
            this.$refs.table.refresh()
          })
        }
      })
    },
    handleSearch () {
      this.$refs.table.initPage()
    },
    handleReset () {
      this.$set(this, 'form', {})
    },
    filterOption (inputValue, path) {
      return path.some(option => option.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    },
    getDataApi (params) {
      this.selectedRowKeys = []
      if (this.isPerms) {
        return getTrainPlanData({
          ...params,
          ...this.getQuery(),
          enterpriseId: this.$store.getters.enterpriseId
        })
      }
      return Promise.resolve({})
    },
    getQuery () {
      const { title, createTime = [], categoryId = [] } = this.form
      return {
        title,
        createTime,
        categoryId: categoryId.length ? categoryId[2] : categoryId.join(',')
      }
    },
    handleAdd () {
      this.$refs.trainPlanForm.createModel()
    },
    handleDeleteAll () {
      const title = '删除'
      this.$confirm({
        title: `批量${title}`,
        content: `确认批量${title}吗？`,
        onOk: () => {
          return new Promise((resolve) => {
            deleteAllTrainPlan(this.selectedRowKeys)
              .then(() => {
                this.$message.success('操作成功')
                this.$refs.table.refresh()
              })
              .finally(() => resolve(true))
          })
        }
      })
    },
    handleDelete (rowItem) {
      deleteAllTrainPlan([rowItem.id]).then(() => {
        this.$message.success('操作成功')
        this.$refs.table.refresh()
      })
    },
    handleOpenPlan (rowItem) {
      this.$refs.trainUser.createModel(rowItem)
    },
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>
<style lang="less">
.train-user {
  .page-form-search {
    /deep/ .ant-form-item {
      margin-bottom: 12px !important;
    }
  }
  .content {
    background: #ffffff;
    padding: 20px;
    overflow: hidden;
  }
  .form_btn_box {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    height: 40px;
  }
}
</style>
